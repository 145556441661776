<template>
  <div class="container dark:bg-gray-900 dark:text-gray-300">
    <div class="py-24">
      <div class="mx-auto w-80">
        <p class="text-4xl font-display">{{ $t("login") }}</p>
        <div class="mt-4">
          <Login @success="handleSuccess" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { useCartStore } from "@/store/cart.js";
import { useBizStore } from "@/store/index.js";

import { useNuxtApp } from "#app";
definePageMeta({
  layout: "store",
});

const { $vLink } = useNuxtApp();
const router = useRouter();
const isLoggedIn = await useApolloLogin();
const bizStore = useBizStore();

if (isLoggedIn) {
  router.push({
    name: "biz-account-profile",
    params: { biz: bizStore.subdomain },
  });
}

async function handleSuccess(redirect) {
  await useCartStore().load();

  // use vlink to handle custom domain biz undefined issue
  $vLink({ name: redirect || "biz-account" });
}
</script>
